<template>
    <div class="box"></div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped lang="stylus"></style>
